'use client';
import { datadogRum } from '@datadog/browser-rum';
const isProduction = process.env.NEXT_PUBLIC_VERCEL_ENV === 'production';
const applicationId = assertValue(
  process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID,
  'Missing environment variable: NEXT_PUBLIC_DATADOG_APPLICATION_ID'
);

const clientToken = assertValue(
  process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN,
  'Missing environment variable: NEXT_PUBLIC_DATADOG_CLIENT_TOKEN'
);

// const version = assertValue(
//   process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA,
//   'Missing environment variable: NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA'
// );

const version = '1';

const site = assertValue(
  process.env.NEXT_PUBLIC_DATADOG_SITE,
  'Missing environment variable: NEXT_PUBLIC_DATADOG_SITE'
);
if (isProduction) {
  datadogRum.init({
    applicationId: applicationId,
    clientToken: clientToken,
    site: site,
    service: 'website',
    version: version,
    env: isProduction ? 'prod' : 'preview',
    sessionSampleRate: 20, // Adjust this value based on your needs
    sessionReplaySampleRate: 20, // Disable session replay sampling
    trackUserInteractions: true, // Enable tracking of user interactions
    trackResources: true, // Enable tracking of resource loads
    trackLongTasks: true, // Enable tracking of long tasks
    defaultPrivacyLevel: 'allow', // Set the default privacy level
  });
}

export default function DatadogInit() {
  // Render nothing - this component is only included so that the init code
  // above will run client-side
  return null;
}

function assertValue<T>(v: T | undefined, errorMessage: string): T {
  if (v === undefined) {
    throw new Error(errorMessage);
  }

  return v;
}
